<template>
  <t-badge :count="count" :offset="[10, 6]" class="fixed bottom-44 right-5 z-50">
    <helper class="customer-service" />
  </t-badge>
</template>

<script setup lang="tsx">
import { fromEvent } from 'rxjs';
import { onMounted, ref } from 'vue';
import helper from '@tencent/iegsp-common/components/ui/helper.vue';
let customerServiceDom = null;
let customerService$ = null;
const count = ref(undefined);
onMounted(() => {
  init();
});

function init() {
  customerServiceDom = document.querySelector('.customer-service');
  if (!customerServiceDom) {
    window.requestAnimationFrame(init);
    return;
  }
  customerService$ = fromEvent(customerServiceDom, 'click');
  window.addEventListener('message', (event) => {
    if (event.data.type === 'newChatCount') {
      count.value = event.data.count;
      console.log(count.value, '11111');
    }
  });
  customerService$.subscribe(() => {
    if (typeof window.AIHelpSupport !== 'undefined') {
      window.AIHelpSupport.show();
    }
    const aiDom = document.querySelector('#AIHelpSupportDiv');
    if (!aiDom) return;
    aiDom.style.position = 'fixed';
    aiDom.style.right = '1.25rem';
    aiDom.style.bottom = '10rem';
    if (aiDom.style.display === 'none') {
      aiDom.style.display = 'block';
    } else {
      aiDom.style.display = 'none';
    }
  });
}
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="less">
.AIHelpSupportBox {
  width: 15vw;
  height: 50vh;
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 99999999999999;
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 20px;
    height: 20px;
    color: #fff;
    background: #eaebec;
    border-radius: 10px;
    cursor: pointer;
    &:before {
      position: absolute;
      content: '';
      width: 15px;
      height: 2px;
      background: #fff;
      transform: rotate(45deg);
      top: 9px;
      left: 3px;
    }
    &:after {
      content: '';
      position: absolute;
      width: 15px;
      height: 2px;
      background: #fff;
      transform: rotate(-45deg);
      top: 9px;
      left: 3px;
    }
  }
}
</style>
