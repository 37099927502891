import { initAegis, getAegis, setConfig } from '@tencent/iegsp-common/tools/utils/galileo';
import { getUserInfo } from '@/api/user';
import { useUserStore } from '@/store';

function init(app) {
  const { userInfo } = useUserStore();
  initAegis({
    id: 'SDK-c8471ebaf23171859daf',
    uid: userInfo?.supplier_user_id,
    app,
    extField: { ext1: userInfo.name, ext2: userInfo.supplier_name, ext3: userInfo.supplier_id },
  });
  getUserInfo().then(() => {
    const { userInfo } = useUserStore();
    if (userInfo?.supplier_user_id) {
      setConfig({
        uid: userInfo?.supplier_user_id,
        extField: { ext1: userInfo.name, ext2: userInfo.supplier_name, ext3: userInfo.supplier_id },
      });
    }
  });
}

export { getAegis, init };
